import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import moment from "moment";
import { convertTimeFormat, DateFormatterTime } from "../../shared/utility";
import momentTime from "moment-timezone";

export const arrivalsBoardSuccess = (res) => {
  let lastUpdated = moment();
  return {
    type: actionTypes.FETCH_ARRIVALSBOARD_DATA,
    arrivalsBoardInfo: res,
    lastUpdated: lastUpdated,
  };
};

export const fetchControlRoomListSuccess = (lists) => {
  return {
    type: actionTypes.FETCH_CONTROLROOM_LIST_SUCCESS,
    controlRoomList: lists,
  };
};
const STATUS_TEXT = {
  NOT_READY: "-",
  READY_FOR_PCR: "PCR",
  READY_FOR_AIR: "AIR",
  READY_FOR_MTC: "MTC",
  CANCELED: "CANC",
  CLEAR: "CLEAR",
};

export const addTallyInfoSuccess = (ifbData, plData, phoData) => {
  return {
    type: actionTypes.ADD_TALLY_INFO,
    ifbData,
    plData,
    phoData,
  };
};

export const getArrivalsBoardInfo = (
  xApiKey,
  time,
  room,
  perPage,
  userList,
  abstractLiveshotName,
  stId,
  timeFormat12Hour,
  debounceInterval,
  expressFetch = false
) => {
  return async (dispatch) => {
    const queryParams = [];
    if (time) {
      queryParams.push("time=" + encodeURIComponent(time));
    }
    if (room) {
      queryParams.push("room=" + encodeURIComponent(room));
    }
    if (perPage && perPage !== "Default") {
      queryParams.push("perPage=" + encodeURIComponent(perPage));
    }
    if (abstractLiveshotName) {
      queryParams.push(
        "abstractLiveshotName=" + encodeURIComponent(abstractLiveshotName)
      );
    }
    if (stId) {
      queryParams.push("stId=" + encodeURIComponent(stId));
    }
    const queryString = queryParams.join("&");
    let now = new Date();
    let backlogArrivalsBoardFetch = localStorage.getItem(
      "backlogArrivalsBoardFetch"
    );
    let nextArrivalsBoardFetch = localStorage.getItem("nextArrivalsBoardFetch");
    if (
      expressFetch ||
      backlogArrivalsBoardFetch === null ||
      backlogArrivalsBoardFetch === "false"
    ) {
      if (nextArrivalsBoardFetch === null || +nextArrivalsBoardFetch <= now) {
        await axios
          .get(`/liveshot-api/arrivalBoard/?${queryString}`, {
            headers: { "x-api-key": xApiKey },
          })
          .then((response) => {
            let mainArray = [];
            let presentTime;
            if (time) {
              let utcTime = new Date(time);
              let usaTime = utcTime.toLocaleString("en-US", {
                timeZone: "America/New_York",
              });
              presentTime = moment(usaTime, "MM/DD/YYYY, hh:mm:ss a").format(
                "HH:mm"
              );
            } else {
              presentTime = momentTime.tz("America/New_York").format("HH:mm");
            }
            let arrivalData = response.data;
            let closestArrivalData;
            if (presentTime) {
              let filteredArrivalData = arrivalData.filter(
                (arrivalsInfo) => arrivalsInfo.hitTime < presentTime
              );
              let today = new Date();
              if (filteredArrivalData.length > 0) {
                closestArrivalData = filteredArrivalData.reduce((a, b) => {
                  const secondValue = DateFormatterTime(b.hitTime);
                  const firstValue = DateFormatterTime(a.hitTime);
                  var target =
                    today.getHours() * 60 * 60 +
                    today.getMinutes() * 60 +
                    today.getSeconds();
                  return Math.abs(secondValue - target) <=
                    Math.abs(firstValue - target)
                    ? b
                    : a;
                });
              }
            }
            for (let key in response.data) {
              const arrivalsInfo = response.data[key];
              let readyText = STATUS_TEXT[arrivalsInfo.status] || "-";
              let windowEndTime, windowStartTime, hitTimeFormatted;
              if (timeFormat12Hour) {
                if (arrivalsInfo.windowEndTime === null) {
                  windowEndTime = "";
                } else {
                  let endTime = moment(arrivalsInfo.windowEndTime, "HH:mm");
                  windowEndTime = convertTimeFormat(endTime);
                }
                if (arrivalsInfo.windowStartTime === null) {
                  windowStartTime = "";
                } else {
                  let startTime = moment(arrivalsInfo.windowStartTime, "HH:mm");
                  windowStartTime = convertTimeFormat(startTime);
                }
                if (arrivalsInfo.hitTime === null) {
                  hitTimeFormatted = "";
                } else {
                  let hitTime = moment(arrivalsInfo.hitTime, "HH:mm");
                  hitTimeFormatted = convertTimeFormat(hitTime);
                }
              } else {
                windowEndTime =
                  arrivalsInfo.windowEndTime === null
                    ? ""
                    : arrivalsInfo.windowEndTime;
                windowStartTime =
                  arrivalsInfo.windowStartTime === null
                    ? ""
                    : arrivalsInfo.windowStartTime;
                hitTimeFormatted =
                  arrivalsInfo.hitTime === null ? "" : arrivalsInfo.hitTime;
              }

              let liveshotBorder;
              if (closestArrivalData) {
                if (closestArrivalData.liveshotId === arrivalsInfo.liveshotId) {
                  liveshotBorder = true;
                } else {
                  liveshotBorder = false;
                }
              }
              let roomValue;
              if (arrivalsInfo.roomCode) {
                let roomCodeValue = arrivalsInfo.roomCode;
                if (roomCodeValue.substring(0, 2) === "CR") {
                  roomValue = roomCodeValue.slice(3);
                } else {
                  roomValue = roomCodeValue;
                }
              }
              let sourceValue;
              if (arrivalsInfo.local) {
                let localValue = arrivalsInfo.local;
                sourceValue = localValue.slice(3);
              }
              mainArray.push({
                ...arrivalsInfo,
                window:
                  windowStartTime || windowEndTime
                    ? windowStartTime + " - " + windowEndTime
                    : "",
                ready: readyText,
                hitTimevalue: hitTimeFormatted,
                liveshotBorder: liveshotBorder,
                roomValue: roomValue,
                sourceValue: sourceValue,
                liveshotUpdatedTooltip: getToolTip(
                  arrivalsInfo.liveshotUpdatedBy,
                  arrivalsInfo.liveshotUpdatedOn,
                  userList
                ),
                inboundUpdatedTooltip: getToolTip(
                  arrivalsInfo.inboundUpdatedBy,
                  arrivalsInfo.inboundUpdatedOn,
                  userList
                ),
                commsUpdatedTooltip: getToolTip(
                  arrivalsInfo.commsUpdatedBy,
                  arrivalsInfo.commsUpdatedOn,
                  userList
                ),
                abstractLiveshotUpdated: "",
                abstractLiveshotIdToolTip: "",
              });
            }
            let now = Date.now();
            localStorage.setItem("backlogArrivalsBoardFetch", false);
            localStorage.setItem(
              "nextArrivalsBoardFetch",
              now + +debounceInterval
            );
            dispatch(arrivalsBoardSuccess(mainArray));
          })
          .catch((err) => {
            console.log("error ", err);
          });
      } else {
        // console.info("Did not call to liveshot-api/arrivalBoard this time due to debounceInterval, calling back in 500ms");
        let backlogArrivalsBoardFetch = localStorage.getItem(
          "backlogArrivalsBoardFetch"
        );
        if (backlogArrivalsBoardFetch === "false") {
          let now = Date.now();
          console.info(
            "Going in to a backlog for " +
              debounceInterval +
              "ms debounceInterval: " +
              now
          );
        }
        localStorage.setItem("backlogArrivalsBoardFetch", true);
        setTimeout(
          getArrivalsBoardInfo(
            xApiKey,
            time,
            room,
            perPage,
            userList,
            abstractLiveshotName,
            stId,
            timeFormat12Hour,
            debounceInterval,
            true
          ),
          500,
          dispatch
        );
      }
    } else {
      console.info(
        "Gratuitous call to liveshot-api/arrivalBoard during backlog, not updating"
      );
    }
  };
};

export const fetchControlRoomList = () => {
  return (dispatch) => {
    axios
      .get("liveshot-api/room/?isCore=false")
      .then((res) => {
        const fetchedControlRoomList = [];
        for (let key in res.data) {
          fetchedControlRoomList.push({
            ...res.data[key],
          });
        }
        dispatch(fetchControlRoomListSuccess(fetchedControlRoomList));
      })
      .catch((err) => {
        //dispatch(actions.noLiveShots());
        console.log(err);
      });
  };
};

const getToolTip = (updateBy, updateOn, userList) => {
  let toolTip = "";
  let updateDateTime = DateFormater(updateOn);

  if (updateDateTime) {
    toolTip += `Updated On: ${updateDateTime[1]} ${updateDateTime[0]}`;
  }

  const userNameList = userList.find(
    (element) => element.sso === parseInt(updateBy)
  );
  if (userNameList) {
    if (toolTip) {
      toolTip += ", ";
    }
    toolTip += `Updated by: ${userNameList.userName}`;
  }
  return toolTip;
};

const DateFormater = (date) => {
  let data = [];
  if (date === null) return;
  let dateFormat = null;
  dateFormat = new Date(date);
  let timeValue = dateFormat.toLocaleTimeString(dateFormat, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  });
  let dateValue = moment(dateFormat).format("ddd MMM Do");
  data.push(timeValue);
  data.push(dateValue);
  return data;
};

export const getTallyInfo = (roomId) => {
  return (dispatch) => {
    axios
      .get(`liveshot-api/arrivalBoard/tallyCommsView?roomId=${roomId}`)
      .then((res) => {
        const ifb = [];
        const pl = [];
        const pho = [];
        //console.log("hello:", res);
        for (let key in res.data) {
          let signalType = res.data[key].signalType;
          if (signalType === 5) {
            ifb.push({
              ...res.data[key],
            });
          }
          if (signalType === 8) {
            pl.push({
              ...res.data[key],
            });
          }
          if (signalType === 9) {
            pho.push({
              ...res.data[key],
            });
          }
        }
        dispatch(addTallyInfoSuccess(ifb, pl, pho));
      })
      .catch((err) => {
        //dispatch(actions.noLiveShots());
        console.log(err);
      });
  };
};
